@use '@angular/material' as mat;
@import '../node_modules/@angular/synthrone-design/assets/styles/synthrone-theme.scss';

$tt-typography: mat.define-typography-config(
  $caption: mat.define-typography-level(12px, 1.4, 400),
);

@include mat.tooltip-typography($tt-typography);

.error{
    small{
        font-size: 10px;
        line-height: 12px !important;
        display: inline-block;
    }
}

#freshwidget-button {
	visibility: hidden;
}

.dense-3 {
    @include mat.all-component-densities(-3);
}
